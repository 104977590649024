
import axios from 'axios';
import { Skater, EvaluationData } from './types';

const API_URL = process.env.NODE_ENV === 'production' ? 'https://argenshow-backend.onrender.com' : 'http://localhost:3501';

interface SkaterParams {
  categories: string[];
  age?: number;
}

const SkaterService = {

  getAllSkaters: async () => {
    try {
      const response = await axios.get(`${API_URL}/skaters`);
      return response.data;
    } catch (error) {
      console.error('Error fetching skaters:', error);
      throw error;
    }
  },

  getReport: async (category: string, age: number) => {
    try {
      const response = await axios.get(`${API_URL}/skaters/report`,
        { 
          params: {
            category, age
          }
        }
      );
      return response;
    } catch (error) {
      console.error('Error fetching skaters:', error);
      throw error;
    }
  },

  updateSkater: async (skaterData: Skater) => {
    try {
      const response = await axios.patch(`${API_URL}/skaters/`, skaterData);
      return response;
    } catch (error) {
      console.error('Error updating skater:', error);
      throw error;
    }
  },

  updateTodaysEvaluations: async (data: EvaluationData) => {
    try {
      const response = await axios.patch(`${API_URL}/skaters/todaysEvaluations`, data);
      return response;
    } catch (error) {
      console.error('Error updating skater:', error);
      throw error;
    }
  },

  getSkatersByCategory: async ( params: SkaterParams ) => {
    try {
      const response = await axios.get(`${API_URL}/skaters`, { params: params });
      return response.data;
    } catch (error) {
      console.error('Error fetching skaters:', error);
      throw error;
    }
  },


  create: async (skaterData: Skater) => {
    try {
      const response = await axios.post(`${API_URL}/skaters`, skaterData);
      return response;
    } catch (error) {
      console.error('Error creating skater:', error);
      throw error;
    }
  },

};

export default SkaterService;