import { Category, ItemDetail } from "./types";

const danceItems: ItemDetail[] = [
  { name: 'Traveling', level: '0', score: 0.0, type: 'travelling' },
  { name: 'Traveling', level: '1', score: 2.5, type: 'travelling' },
  { name: 'Traveling', level: '2', score: 3.5, type: 'travelling' },
  { name: 'Traveling', level: '3', score: 4.5, type: 'travelling' },
  { name: 'Traveling', level: '4', score: 6.0, type: 'travelling' },
  { name: 'Traveling', level: '5', score: 6.5, type: 'travelling' },

  { name: 'Artistic Seq', level: '0', score: 0.0, type: 'artistic' },
  { name: 'Artistic Seq', level: '1', score: 3.0, type: 'artistic' },
  { name: 'Artistic Seq', level: '2', score: 4.0, type: 'artistic' },
  { name: 'Artistic Seq', level: '3', score: 5.5, type: 'artistic' },
  { name: 'Artistic Seq', level: '4', score: 7.1, type: 'artistic' },
  { name: 'Artistic Seq', level: '5', score: 9.3, type: 'artistic' },

  { name: 'Dance Step Seq', level: '0', score: 0.0, type: 'step' },
  { name: 'Dance Step Seq', level: '1', score: 3.0, type: 'step' },
  { name: 'Dance Step Seq', level: '2', score: 4.0, type: 'step' },
  { name: 'Dance Step Seq', level: '3', score: 5.5, type: 'step' },
  { name: 'Dance Step Seq', level: '4', score: 7.1, type: 'step' },
  { name: 'Dance Step Seq', level: '5', score: 9.3, type: 'step' },

  { name: 'Foot Seq', level: '0', score: 0.0, type: 'foot' },
  { name: 'Foot Seq', level: '1', score: 4.0, type: 'foot' },
  { name: 'Foot Seq', level: '2', score: 5.0, type: 'foot' },
  { name: 'Foot Seq', level: '3', score: 6.5, type: 'foot' },
  { name: 'Foot Seq', level: '4', score: 8.1, type: 'foot' },
  { name: 'Foot Seq', level: '5', score: 10.3, type: 'foot' },

  { name: 'Cluster Sequence', level: '0', score: 0.0, type: 'cluster' },
  { name: 'Cluster Sequence', level: '1', score: 3.0, type: 'cluster' },
  { name: 'Cluster Sequence', level: '2', score: 4.0, type: 'cluster' },
  { name: 'Cluster Sequence', level: '3', score: 5.3, type: 'cluster' },
  { name: 'Cluster Sequence', level: '4', score: 6.9, type: 'cluster' },
  { name: 'Cluster Sequence', level: '5', score: 7.5, type: 'cluster' },

  { name: 'Choreo Step Sequence', level: '0', score: 0.0, type: 'choreo' },
  { name: 'Choreo Step Sequence', level: '1', score: 3.0, type: 'choreo' },

  { name: 'Pattern Sequence', level: '0', score: 1.5, type: 'pattern' },
  { name: 'Pattern Sequence', level: '1', score: 2, type: 'pattern' },
  { name: 'Pattern Sequence', level: '2', score: 2.5, type: 'pattern' },
  { name: 'Pattern Sequence', level: '3', score: 3, type: 'pattern' },
  { name: 'Pattern Sequence', level: '4', score: 3.5, type: 'pattern' },

]
const quartetsItems: ItemDetail[] = [
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '0',
    score: 0.0
  },
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '1',
    score: 2.5
  },
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '2',
    score: 3.5
  },
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '3',
    score: 4.5
  },
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '4',
    score: 6.0
  },
  {
    type: 'travelling',
    name: 'TravelingSequence',
    level: '5',
    score: 6.5
  },
  {
    type: 'creativa',
    name: 'ElementoCreativo',
    level: 'No',
    score: 0.0
  },
  {
    type: 'creativa',
    name: 'ElementoCreativo',
    level: '1',
    score: 3.0
  },
  {
    type: 'canon',
    name: 'Canon',
    level: 'No',
    score: 0.0
  },
  {
    type: 'canon',
    name: 'Canon',
    level: '1',
    score: 2.8
  },
  {
    type: 'canon',
    name: 'Canon',
    level: '2',
    score: 3.8
  },
  {
    type: 'canon',
    name: 'Canon',
    level: '3',
    score: 4.8
  },
  {
    type: 'canon',
    name: 'Canon',
    level: '4',
    score: 6.3
  },
  {
    type: 'canon',
    name: 'Canon',
    level: '5',
    score: 6.8
  }
]
const items: ItemDetail[] = [

  { name: 'Figure', score: 0.5, type: 'figure' },
  { name: 'Combo Figure', score: 1.0, type: 'comboFigures' },
  { name: 'Choreo Step Sequence', score: 1.0, type: 'danceElement' },
  { name: 'Skating Skill Sequence', score: 1.0, type: 'danceElement' },
  { name: 'Combo Spins', score: 0.2, type: 'comboSpins' },
  { name: 'Combo Jumps', score: 0.2, type: 'comboJumps' },
  { name: 'No Jump', score: 0.0, type: 'jump' },
  { name: 'Salto Simple', score: 1, type: 'jump' },
  { name: 'Waltz Jump', score: 0.4, type: 'jump' },
  { name: 'Axel', score: 1.3, type: 'jump' },
  { name: 'Salto Doble', score: 2.5, type: 'jump' },
  { name: '2 Axel', score: 6.1, type: 'jump' },
  { name: 'Salto Triple', score: 7.5, type: 'jump' },
  { name: 'No Spin', score: 0.0, type: 'spin' },
  { name: 'Upright Spin', score: 0.5, type: 'spin' },
  { name: 'Sit Spins', score: 0.8, type: 'spin' },
  { name: 'Camel', score: 1.2, type: 'spin' },
  { name: 'Broken Spin', score: 1.8, type: 'spin' },
  { name: 'Heel Spin Backward', score: 2.0, type: 'spin' },
  { name: 'Heel Spin Forward', score: 2.5, type: 'spin' },
  { name: 'Inverted Spin', score: 2.8, type: 'spin' }
]

const showItems = [
  {
    type: 'choreo',
    name: 'Level Idea and Choreography',
    level: '0',
    score: 0.0
  },
  {
    type: 'choreo',
    name: 'Level Idea and Choreography',
    level: '1',
    score: 1.5
  },
  {
    type: 'group tech',
    name: 'Group Technique',
    level: '0',
    score: 0.0
  },
  {
    type: 'group tech',
    name: 'Group Technique',
    level: '1',
    score: 1.2
  },
  {
    type: 'performance',
    name: 'Performance',
    level: '0',
    score: 0.0
  },
  {
    type: 'performance',
    name: 'Performance',
    level: '1',
    score: 1.0
  },
  {
    type: 'skating skill',
    name: 'Skating Skill',
    level: '0',
    score: 0.0
  },
  {
    type: 'skating skill',
    name: 'Skating Skill',
    level: '1',
    score: 1.0
  }
];

const coupleItems: ItemDetail[] = [
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '0', score: 0.0 },
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '1', score: 1.5 },
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '2', score: 2.5 },
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '3', score: 3.5 },
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '4', score: 4.5 },
  { name: 'Dance Lift Rotational', type: 'danceLiftRotational', level: '5', score: 5.5 },

  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '0', score: 0.0 },
  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '1', score: 1.5 },
  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '2', score: 2.5 },
  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '3', score: 3.5 },
  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '4', score: 4.5 },
  { name: 'Dance Lift Stationary', type: 'danceLiftStationary', level: '5', score: 5.5 },

  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '0', score: 0.0 },
  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '1', score: 2 },
  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '2', score: 3 },
  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '3', score: 4 },
  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '4', score: 5 },
  { name: 'Dance Lift Combo', type: 'danceLiftCombo', level: '5', score: 6.5 },

  { name: 'Choreographic Lift', type: 'choreo', level: '0', score: 0.0 },
  { name: 'Choreographic Lift', type: 'choreo', level: '5', score: 2.0 },

  { name: 'Traveling', type: 'traveling', level: '0', score: 0.0 },
  { name: 'Traveling', type: 'traveling', level: '1', score: 2.5 },
  { name: 'Traveling', type: 'traveling', level: '2', score: 3.5 },
  { name: 'Traveling', type: 'traveling', level: '3', score: 4.5 },
  { name: 'Traveling', type: 'traveling', level: '4', score: 6.0 },
  { name: 'Traveling', type: 'traveling', level: '5', score: 6.5 },

  { name: 'No hold footwork sequence', type: 'noHoldFootwork', level: '0', score: 0.0 },
  { name: 'No hold footworks equence', type: 'noHoldFootwork', level: '1', score: 4.0 },
  { name: 'No hold footwork sequence', type: 'noHoldFootwork', level: '2', score: 5.0 },
  { name: 'No hold footwork sequence', type: 'noHoldFootwork', level: '3', score: 6.3 },
  { name: 'No hold footwork sequence', type: 'noHoldFootwork', level: '4', score: 7.9 },
  { name: 'No hold footwork sequence', type: 'noHoldFootwork', level: '5', score: 10.1 },

  { name: 'hold footwork sequence', type: 'holdFootwork', level: '0', score: 0.0 },
  { name: 'hold footwork sequence', type: 'holdFootwork', level: '1', score: 4.5 },
  { name: 'hold footworks equence', type: 'holdFootwork', level: '2', score: 5.5 },

  { name: 'hold cluster', type: 'cluster', level: '0', score: 0.0 },
  { name: 'hold cluster', type: 'cluster', level: '1', score: 4.5 },
  { name: 'hold cluster', type: 'cluster', level: '2', score: 5.5 },
  { name: 'hold cluster', type: 'cluster', level: '3', score: 6.5 },
  { name: 'hold cluster', type: 'cluster', level: '4', score: 7.5 },
]

const categories: Category[] = [
  {
    name: "Escuela Formativa",
    rules: {
      maxIndividualJumps: 0,
      maxIndividualSpins: 2,
      maxIndividualFigures: 4,
      maxComboJumps: 0,
      maxComboSpins: 0,
      maxComboFigures: 0,
    },
    type: 'libre'
  },
  {
    name: "5C",
    rules: {
      maxIndividualJumps: 0,
      maxIndividualSpins: 2,
      maxIndividualFigures: 0,
      maxComboJumps: 0,
      maxComboSpins: 0,
      maxComboFigures: 1,
    },
    type: 'libre'
  },
  {
    name: "4C",
    rules: {
      maxIndividualJumps: 2,
      maxIndividualSpins: 2,
      maxIndividualFigures: 0,
      maxComboJumps: 0,
      maxComboSpins: 0,
      maxComboFigures: 1,
    },
    type: 'libre'
  },
  {
    name: "3C",
    rules: {
      maxIndividualJumps: 5,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
    },
    type: 'libre'
  },
  {
    name: "2C",
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 1,
      maxComboFigures: 0,
    },
    type: 'libre'
  },
  {
    name: "1C",
    rules: {
      maxIndividualJumps: 10,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 1,
      maxComboFigures: 0,
    },
    type: 'libre'
  },
  {
    name: 'PROMO B',
    rules: {
      maxIndividualJumps: 12,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 1
    },
    type: 'libre'
  },
  {
    name: '3B',
    rules: {
      maxIndividualJumps: 12,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 1
    },
    type: 'libre'
  },
  {
    name: '2B',
    rules: {
      maxIndividualJumps: 2,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 1
    },
    type: 'libre'
  },
  {
    name: '1B',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 2
    },
    type: 'libre'
  },
  {
    name: 'NACIONAL TOOTS',
    rules: {
      maxIndividualJumps: 12,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 1
    },
    type: 'libre-solo'
  },
  {
    name: 'NACIONAL MINIS',
    rules: {
      maxIndividualJumps: 12,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 2
    },
    type: 'libre-solo'
  },
  {
    name: 'NACIONAL INFANTIL',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },
  {
    name: 'ELITE SHORT PROGRAM',
    rules: {
      maxIndividualJumps: 2,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },
  {
    name: 'ELITE LONG PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualFigures: 0,
      maxComboJumps: 3,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },
  {
    name: 'NACIONAL CADETE PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },

  {
    name: 'NACIONAL JUVENIL PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },
  {
    name: 'NACIONAL JUNIOR PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },

  {
    name: 'NACIONAL SENIOR PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },

  {
    name: 'CADETE WS SHORT PROGRAM',
    rules: {
      maxIndividualJumps: 1,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },

  {
    name: 'CADETE WS LONG PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxComboJumps: 2,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 3
    },
    type: 'libre-solo'
  },

  {
    name: 'JUVENIL WS SHORT PROGRAM',
    rules: {
      maxIndividualJumps: 1,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },

  {
    name: 'JUVENIL WS LONG PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxComboJumps: 3,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },

  {
    name: 'JUNIOR WS SHORT PROGRAM',
    rules: {
      maxIndividualJumps: 1,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },

  {
    name: 'JUNIOR WS LONG PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxComboJumps: 3,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },

  {
    name: 'SENIOR WS SHORT PROGRAM',
    rules: {
      maxIndividualJumps: 1,
      maxIndividualSpins: 1,
      maxIndividualAxel: 1,
      maxComboJumps: 1,
      maxComboSpins: 1,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },

  {
    name: 'SENIOR WS LONG PROGRAM',
    rules: {
      maxIndividualJumps: 8,
      maxIndividualSpins: 1,
      maxComboJumps: 3,
      maxComboSpins: 2,
      maxComboFigures: 0,
      maxLevelFootwork: 4
    },
    type: 'libre-solo'
  },
  {
    name: 'Intermedio Tots Freedance',
    rules: {
      maxLevelStepSequence: 1,
      maxLevelTravelling: 1,
      maxLevelFootwork: 1,
      maxLevelArtistic: 0,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Tots Danza 1',
    rules: {
      maxLevelPatternSequence: 2,
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Tots Danza 2',
    rules: {
      maxLevelPatternSequence: 2,

    },
    type: 'dance'
  },
  {
    name: 'Intermedio Mini Infantil Danza 1',
    rules: {
      maxLevelPatternSequence: 2,
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Mini Infantil Danza 2',
    rules: {
      maxLevelPatternSequence: 2,
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Mini Infantil Freedance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 1,
      maxLevelFootwork: 1,
      maxLevelArtistic: 1,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Infantil Freedance',
    rules: {
      maxLevelStepSequence: 1,
      maxLevelTravelling: 1,
      maxLevelFootwork: 1,
      maxLevelArtistic: 0,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Infantil Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Infantil Danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Cadete',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 2,
      maxLevelFootwork: 2,
      maxLevelArtistic: 0,
      maxLevelCluster: 1
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Cadete Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Juvenil Style',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 2,
      maxLevelFootwork: 0,
      maxLevelArtistic: 3,
      maxLevelCluster: 2,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Juvenil Free dance',
    rules: {
      maxLevelStepSequence: 3,
      maxLevelTravelling: 3,
      maxLevelFootwork: 3,
      maxLevelArtistic: 0,
      maxLevelCluster: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Junior Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 3,
      maxLevelFootwork: 0,
      maxLevelArtistic: 3,
      maxLevelCluster: 3,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Junior Free dance',
    rules: {
      maxLevelStepSequence: 3,
      maxLevelTravelling: 3,
      maxLevelFootwork: 3,
      maxLevelArtistic: 0,
      maxLevelCluster: 3
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Senior Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 3,
      maxLevelFootwork: 0,
      maxLevelArtistic: 3,
      maxLevelCluster: 3,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Senior Free dance',
    rules: {
      maxLevelStepSequence: 3,
      maxLevelTravelling: 3,
      maxLevelFootwork: 3,
      maxLevelArtistic: 0,
      maxLevelCluster: 3
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Clasico Freedance',
    rules: {
      maxLevelStepSequence: 3,
      maxLevelTravelling: 2,
      maxLevelFootwork: 3,
      maxLevelArtistic: 0,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Clasico Danza 1',
    rules: {
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Clasico Danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Novicio Freedance',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 0,
      maxLevelFootwork: 0,
      maxLevelArtistic: 2,
      maxLevelCluster: 0,
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Novicio Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Novicio Danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Profesional Freedance',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 0,
      maxLevelFootwork: 2,
      maxLevelArtistic: 0,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Profesional Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio Profesional Danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio master Freedance',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 0,
      maxLevelFootwork: 2,
      maxLevelArtistic: 0,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Juvenil Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Juvenil Freedance',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 2,
      maxLevelFootwork: 2,
      maxLevelCluster: 1
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Junior Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Junior Freedance',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 2,
      maxLevelFootwork: 2,
      maxLevelCluster: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Cadete Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Cadete Freedance',
    rules: {
      maxLevelTravelling: 1,
      maxLevelFootwork: 1,
      maxLevelArtistic: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Novicio Danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Novicio Danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Nacional Basico Novicio Freedance',
    rules: {
      maxLevelStepSequence: 1,
      maxLevelArtistic: 1,
    },
    type: 'dance'
  },
  {
    name: 'Intermedio master danza 1',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Intermedio master danza 2',
    rules: {
      maxLevelPatternSequence: 2
    },
    type: 'dance'
  },
  {
    name: 'Elite Junior B Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Elite Junior B Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    name: 'Elite Senior B Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Elite Senior B Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    name: 'Elite Leyenda Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 3,
      maxLevelFootwork: 0,
      maxLevelArtistic: 3,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Elite Leyenda Free dance',
    rules: {
      maxLevelStepSequence: 3,
      maxLevelTravelling: 3,
      maxLevelFootwork: 3,
      maxLevelArtistic: 0,
      maxLevelCluster: 3
    },
    type: 'dance'
  },
  {
    name: 'Tots World Skate',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 1,
      maxLevelFootwork: 0,
      maxLevelArtistic: 1,
      maxLevelCluster: 0
    },
    type: 'dance'
  },
  {
    name: 'Mini Infantil World Skate',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 2,
      maxLevelFootwork: 0,
      maxLevelArtistic: 0,
      maxLevelCluster: 1
    },
    type: 'dance'
  },
  {
    name: 'Infantil World Skate',
    rules: {
      maxLevelStepSequence: 2,
      maxLevelTravelling: 3,
      maxLevelFootwork: 0,
      maxLevelArtistic: 0,
      maxLevelCluster: 2
    },
    type: 'dance'
  },
  {
    name: 'Cadete World Skate Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Juvenil World Skate Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Junior World Skate Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Senior World Skate Style dance',
    rules: {
      maxLevelStepSequence: 0,
      maxLevelTravelling: 4,
      maxLevelFootwork: 0,
      maxLevelArtistic: 4,
      maxLevelCluster: 4,
      maxLevelPatternSequence: 4
    },
    type: 'dance'
  },
  {
    name: 'Cadete World Skate Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    name: 'Juvenil World Skate Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    name: 'Junior World Skate Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    name: 'Senior World Skate Free dance',
    rules: {
      maxLevelStepSequence: 4,
      maxLevelTravelling: 4,
      maxLevelFootwork: 4,
      maxLevelArtistic: 0,
      maxLevelCluster: 4
    },
    type: 'dance'
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Infantil Nacional",
    rules: {
      maxLevelCanon: 2,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 2
    }
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Junior Nacional",
    rules: {
      maxLevelCanon: 3,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 3
    }
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Senior Nacional",
    rules: {
      maxLevelCanon: 3,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 3
    }
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Cadete WS",
    rules: {
      maxLevelCanon: 4,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 4
    }
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Junior WS",
    rules: {
      maxLevelCanon: 4,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 4
    }
  },
  {
    type: 'cuarteto',
    name: "Cuarteto Senior WS",
    rules: {
      maxLevelCanon: 4,
      maxLevelElementoCreativo: 1,
      maxLevelTravellingSequence: 4
    }
  },
  {
    type: 'show',
    name: 'Small Nacional',
    rules: {
      maxLevelIdeaAndChoreography: 1,
      maxLevelGroupTechnique: 1,
      maxLevelPerformance: 1,
      maxLevelSkatingSkill: 1
    }
  },
  {
    type: 'show',
    name: 'Small WS',
    rules: {
      maxLevelIdeaAndChoreography: 1,
      maxLevelGroupTechnique: 1,
      maxLevelPerformance: 1,
      maxLevelSkatingSkill: 1
    }
  },
  {
    name: 'Style Dance Senior Couple',
    type: 'couple',
    rules: {
      maxLevelholdfootworksequence: 5,
      maxLevelTravelling: 5,
      maxLevelDanceLiftCombo: 5,
      maxLevelpatterndancesequence: 4
    }
  },

  {
    name: 'Free Dance Senior Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftStationary: 5,
      maxLevelDanceLiftRotational: 5,
      maxLevelChoreographicLift: 5,
      maxLevelNoholdfootworksequence: 5,
      maxLevelOnepartnerfootworksequence: 5,
      maxLevelHoldclustersequence: 5
    }
  },

  {
    name: 'Style Dance Junior Couple',
    type: 'couple',
    rules: {
      maxLevelholdfootworksequence: 5,
      maxLevelTravelling: 5,
      maxLevelDanceLiftCombo: 5,
      maxLevelpatterndancesequence: 4
    }
  },

  {
    name: 'Free Dance Junior Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftStationary: 5,
      maxLevelDanceLiftRotational: 5,
      maxLevelChoreographicLift: 5,
      maxLevelNoholdfootworksequence: 5,
      maxLevelOnepartnerfootworksequence: 5,
      maxLevelHoldclustersequence: 5
    }
  },

  {
    name: 'Style Dance Youth Couple',
    type: 'couple',
    rules: {
      maxLevelholdfootworksequence: 5,
      maxLevelTravelling: 5,
      maxLevelDanceLiftRotational: 5,
      maxLevelpatterndancesequence: 4
    }
  },

  {
    name: 'Free Dance Youth Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftStationary: 3,
      maxLevelOnepartnerfootworksequence: 5,
      maxLevelChoreographicLift: 5,
      maxLevelNoholdfootworksequence: 5,
      maxLevelDanceLiftCombo: 3,
      maxLevelHoldclustersequence: 5
    }
  },

  {
    name: 'Style Dance Cadet Couple',
    type: 'couple',
    rules: {
      maxLevelholdfootworksequence: 3,
      maxLevelTravelling: 3,
      maxLevelDanceLiftRotational: 3,
      maxLevelpatterndancesequence: 4
    }
  },

  {
    name: 'Free Dance Cadet Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftStationary: 3,
      maxLevelOnepartnerfootworksequence: 3,
      maxLevelChoreographicLift: 5,
      maxLevelNoholdfootworksequence: 3,
      maxLevelDanceLiftCombo: 3,
      maxLevelHoldclustersequence: 3
    }
  },

  {
    name: 'Free Dance Espoir Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftRotational: 2,
      maxLevelOnepartnerfootworksequence: 2,
      maxLevelChoreographicLift: 5,
      maxLevelholdfootworksequence: 2,
      maxLevelTravelling: 2
    }
  },

  {
    name: 'Free Dance Minis Couple',
    type: 'couple',
    rules: {
      maxLevelDanceLiftStationary: 1,
      maxLevelChoreographicLift: 5,
      maxLevelholdfootworksequence: 1,
      maxLevelTravelling: 2
    }
  },

  {
    name: 'Free Dance Tots Couple',
    type: 'couple',
    rules: {
      maxLevelChoreographicLift: 5,
      maxLevelNoholdfootworksequence: 1,
      maxLevelTravelling: 1
    }
  },
]

const availableJudges = [
  {
    name: 'Juez 1',
    score: 0,
  },
  {
    name: 'Juez 2',
    score: 0,
  },
  {
    name: 'Juez 3',
    score: 0
  }
]

export { danceItems, items, categories, quartetsItems, showItems, availableJudges, coupleItems };

export const ages = [0, ...Array.from({ length: 30 }, (_, index) => index + 1)];