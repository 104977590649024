import React from "react";
import { DanceCategory } from "../DanceCategory";
import { Box } from "@mui/material";
import { coupleItems, danceItems, quartetsItems, showItems } from "../variables";
import { Category } from "../types";

const DanceCategories = ({
  selectedCategory,
  setTotalScore,
}: {
  selectedCategory: Category,
  setTotalScore: (score: number) => void,
}) => {
  const travellingItems = danceItems.filter(
    (item) => item.type === "travelling"
  );
  const stepSequenceItems = danceItems.filter((item) => item.type === "step");
  const clusterItems = danceItems.filter((item) => item.type === "cluster");
  const artisticItems = danceItems.filter((item) => item.type === "artistic");
  const footworkItems = danceItems.filter((item) => item.type === "foot")
  const patternSequence = danceItems.filter((item) => item.type === "pattern");
  const quartetsTraveling = quartetsItems.filter((item) => item.type === "travelling");
  const quartetsCanon = quartetsItems.filter((item) => item.type === "canon");
  const quartetsCreativa = quartetsItems.filter((item) => item.type === "creativa");
  const showChoreo = showItems.filter((item) => item.type === "choreo");
  const showTechnique = showItems.filter((item) => item.type === "group tech");
  const showPerfo = showItems.filter((item) => item.type === "performance");
  const showSkatingSkill = showItems.filter((item) => item.type === "skating skill");
  const danceChoreoItem = danceItems.filter((item) => item.type === "choreo");

  const coupleDanceChoreoItems = coupleItems.filter( item => item.type === 'choreo')
  const coupleDanceLiftRotationalItems = coupleItems.filter( item => item.type === 'danceLiftRotational')
  const coupleDanceLifStationary = coupleItems.filter( item => item.type === 'danceLiftStationary')
  // const coupleDanceTraveling = coupleItems.filter( item => item.type === 'traveling')
  const coupleDanceLiftCombo = coupleItems.filter( item => item.type === 'danceLiftCombo')
  const coupleDanceHoldCluster = coupleItems.filter( item => item.type === 'cluster')
  const coupleDanceNoHoldFootworkSequence = coupleItems.filter( item => item.type === 'holdFootwork')
  const coupleDanceHoldFootworkSequence = coupleItems.filter( item => item.type === 'holdFootwork')

  return (
    <Box display="flex" flexDirection="column">
      {/* danceChoreoItem: {JSON.stringify(danceChoreoItem)} */}
      <DanceCategory
        items={danceChoreoItem}
        maxLevel={1}
        setTotalScore={setTotalScore}
        disabled={false}
        title='Choreo Step'
      />
       {selectedCategory.rules.maxLevelTravelling &&
        (<DanceCategory
          items={travellingItems}
          maxLevel={selectedCategory.rules.maxLevelTravelling}
          setTotalScore={setTotalScore}
          disabled={false}
          title="Traveling Item"
        />)}
      {selectedCategory.rules.maxLevelCluster &&
        (<DanceCategory
          items={clusterItems}
          maxLevel={selectedCategory.rules.maxLevelCluster}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Cluster Item'
        />
      )}
      {selectedCategory.rules.maxLevelArtistic &&
        (<DanceCategory
          items={artisticItems}
          maxLevel={selectedCategory.rules.maxLevelArtistic}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Artistic Sequence Item'
        />
      )}

      {selectedCategory.rules.maxLevelPatternSequence &&
        (<DanceCategory
          items={patternSequence}
          maxLevel={selectedCategory.rules.maxLevelPatternSequence}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Pattern Sequence'
        />
      )}
      {selectedCategory.rules.maxLevelStepSequence &&
        (
          <DanceCategory
            items={stepSequenceItems}
            maxLevel={selectedCategory.rules.maxLevelStepSequence}
            setTotalScore={setTotalScore}
            disabled={false}
            title='Step Sequence Item'
          />
        )}
      {selectedCategory.rules.maxLevelFootwork &&
        (<DanceCategory
          items={footworkItems}
          maxLevel={selectedCategory.rules.maxLevelFootwork}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Footwork Sequence Item'
        />
      )}

      {selectedCategory.rules.maxLevelTravellingSequence &&
        (<DanceCategory
          items={quartetsTraveling}
          maxLevel={selectedCategory.rules.maxLevelTravellingSequence}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Cuarteto - Traveling Sequence'
        />
      )}

      {selectedCategory.rules.maxLevelCanon &&
        (<DanceCategory
          items={quartetsCanon}
          maxLevel={selectedCategory.rules.maxLevelCanon}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Cuarteto - Canon'
        />
      )}
      {selectedCategory.rules.maxLevelElementoCreativo &&
        (<DanceCategory
          items={quartetsCreativa}
          maxLevel={selectedCategory.rules.maxLevelElementoCreativo}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Cuarteto - Elemento Creativo'
        />
      )}
      {selectedCategory.rules.maxLevelIdeaAndChoreography &&
        (<DanceCategory
          items={showChoreo}
          maxLevel={selectedCategory.rules.maxLevelIdeaAndChoreography}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Show - Idea y Coreografía'
        />
      )}
      {selectedCategory.rules.maxLevelSkatingSkill &&
        (<DanceCategory
          items={showSkatingSkill}
          maxLevel={selectedCategory.rules.maxLevelSkatingSkill}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Show - Skating Skill'
        />
      )}
      {selectedCategory.rules.maxLevelPerformance &&
        (<DanceCategory
          items={showPerfo}
          maxLevel={selectedCategory.rules.maxLevelPerformance}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Show - Performance'
        />
      )}
      {selectedCategory.rules.maxLevelGroupTechnique &&
        (<DanceCategory
          items={showTechnique}
          maxLevel={selectedCategory.rules.maxLevelGroupTechnique}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Show - Técnica de Grupo'
        />
      )}
      {selectedCategory.rules.maxLevelDanceLiftStationary &&
        (<DanceCategory
          items={coupleDanceLifStationary}
          maxLevel={selectedCategory.rules.maxLevelDanceLiftStationary}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Dance Lift Stationary'
        />
      )}
      {selectedCategory.rules.maxLevelDanceLiftRotational &&
        (<DanceCategory
          items={coupleDanceLiftRotationalItems}
          maxLevel={selectedCategory.rules.maxLevelDanceLiftRotational}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Dance Lift Rotational'
        />
      )}
      {selectedCategory.rules.maxLevelChoreographicLift &&
        (<DanceCategory
          items={coupleDanceChoreoItems}
          maxLevel={selectedCategory.rules.maxLevelChoreographicLift}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Choreographic Lift'
        />
        )}
      {selectedCategory.rules.maxLevelNoholdfootworksequence &&
        (<DanceCategory
          items={coupleDanceNoHoldFootworkSequence}
          maxLevel={selectedCategory.rules.maxLevelNoholdfootworksequence}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - No Hold Footwork Sequence'
        />
        )}
      {selectedCategory.rules.maxLevelOnepartnerfootworksequence &&
        (<DanceCategory
          items={coupleDanceHoldFootworkSequence}
          maxLevel={selectedCategory.rules.maxLevelOnepartnerfootworksequence}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Hold Footwork Sequence'
        />
        )}
      {selectedCategory.rules.maxLevelHoldclustersequence &&
        (<DanceCategory
          items={coupleDanceHoldCluster}
          maxLevel={selectedCategory.rules.maxLevelHoldclustersequence}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Cluster Sequence'
        />
        )}
      {selectedCategory.rules.maxLevelDanceLiftCombo &&
        (<DanceCategory
          items={coupleDanceLiftCombo}
          maxLevel={selectedCategory.rules.maxLevelDanceLiftCombo}
          setTotalScore={setTotalScore}
          disabled={false}
          title='Couple Dance - Dance Lift Combo'
        />
        )}
    </Box>
  )
}

export default DanceCategories;