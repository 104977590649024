import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  skaterName: string;
  totalScore: number;
}
const styles = { backgroundColor: "#fff",
borderRadius: "10px",
padding: "20px",
marginBottom: "20px"
}
const TotalScore: React.FC<Props> = ({ skaterName, totalScore }) => {
  return (
    <Box sx={styles}>
      <Typography variant="h5">Nombre del patinador: {skaterName}</Typography>
      <Typography variant="h5">Total Score: {totalScore}</Typography>
    </Box>
  );
};

export default TotalScore;
