import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Snackbar,
} from "@mui/material";
import TotalScore from "../TotalScore";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CircularProgress from '@mui/material/CircularProgress';
import { Judge, Skater } from "../types";

const EvaluatedSkater = ({
  selectedSkater,
  selectedJudge,
  totalScore,
  error,
  disableSendScore,
  handleAdditionalScore,
  handleLessScore,
  sendScore,
  setError,
}: {
  selectedSkater: Skater,
  selectedJudge: Judge,
  totalScore: number,
  error: undefined | string,
  disableSendScore: boolean,
  handleAdditionalScore: () => void,
  handleLessScore: () => void,
  sendScore: () => void,
  setError: (error: string | undefined) => void,
}) => {
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  return (
    <Box sx={{ backgroundColor: '#ffff', margin: 0, fontWeight: 600, borderRadius: '5px' }} flex={1} mr={2}>
      <>
        <Box mt={1} textAlign="center">
          <TotalScore skaterName={`${selectedSkater.name} ${selectedSkater.lastname}`} totalScore={totalScore} />
        </Box>

        <Box mt={1} textAlign="center" padding={1}>
          <Button variant="contained" onClick={handleAdditionalScore}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }}>Puntaje Adicional (+0.5)</Typography>
              <AddCircleOutlineIcon sx={{ ml: 1 }} />
            </Box>
          </Button>
          <Button variant="contained" onClick={handleLessScore} sx={{ marginLeft: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography sx={{ fontWeight: 600 }}>Deducción (-0.5)</Typography>
              <RemoveCircleOutlineIcon sx={{ ml: 1 }} />
            </Box>
          </Button>
          {/* Botón para enviar la calificación */}
          <Button
            sx={{ marginLeft: 2 }}
            variant="contained"
            onClick={sendScore}
            disabled={!selectedJudge || !selectedSkater || disableSendScore} // Deshabilita el botón si no hay juez seleccionado o patinador seleccionado
          >
            <Typography sx={{ fontWeight: 600, display: 'flex', alignItems: 'center' }}>
              <span>Enviar calificación</span>
              {disableSendScore && <CircularProgress sx={{marginLeft: '15px' }} size="1rem" />}
            </Typography>
          </Button>
        </Box>
        <Snackbar
          open={showSuccessBanner}
          autoHideDuration={6000}
          onClose={() => setShowSuccessBanner(false)}
          message="Patinador cargado exitosamente."
          style={{ backgroundColor: 'green', color: 'white', zIndex: 1000, borderRadius: '10px' }}
        />

        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(undefined)}
            message={error}
            color='red'
            style={{ backgroundColor: 'red', color: 'white', zIndex: 1000, borderRadius: '10px' }}
          />
        )}
      </>
    </Box>
  )
}

export default EvaluatedSkater;