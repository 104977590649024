import React, { useState } from 'react';
import FrontEndPatinaje from './FrontEndPatinaje';
import SkaterForm from './SkaterForm';
import Reporte from './views/Reporte';
import MainMenu from './MainMenu';
import { Box } from '@material-ui/core';
import Banner from './Banner';
// import ArgenshowContext from './contexts/context';

const App = () => {
  const [selectedOption, setSelectedOption] = useState('');
  // const [evaluatedSkaterChanged, setEvaluatedSkaterChanged] = useState(false);

  // const defaultArgenshowProviderData = {
  //   evaluatedSkaterChanged,
  //   setEvaluatedSkaterChanged
  // }

  return (
    // <ArgenshowContext.Provider value={defaultArgenshowProviderData}>
      <Box className="App" sx={{ width: '100%', display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'linear-gradient(135deg, #4223d6, #e7aaff)', maxHeight: '100vh' }}>
        <Banner>
            <Box sx={{ position: 'relative', zIndex: 3 }}> 
              <MainMenu onSelectOption={setSelectedOption} />
            </Box>
          </Banner>
        {selectedOption === 'cargar-patinador' && <SkaterForm />}
        {selectedOption === 'evaluar' && <FrontEndPatinaje />}
        {selectedOption === 'generar-reporte' && <Reporte />}
      </Box>
    // </ArgenshowContext.Provider>
  );
};

export default App;
