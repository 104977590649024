import React, { useState } from 'react';
import { Box, Radio, RadioGroup, FormControlLabel, Typography, makeStyles } from '@material-ui/core';
import { ItemDetail } from './types';

const useStyles = makeStyles({
  danceCategory: {
    backgroundColor: 'white',
    padding: '10px',
    margin: '10px',
    borderRadius: '5px',
    display: "flex",
    flexDirection: "column",
  },
  danceItem: {
    display: 'flex',
    alignItems: 'center',
  },
});

interface DanceCategoryProps {
  items: ItemDetail[];
  maxLevel: number;
  setTotalScore: (score: any) => void;
  disabled: boolean;
  title: string;
}

export const DanceCategory: React.FC<DanceCategoryProps> = ({
  items,
  maxLevel,
  setTotalScore,
  disabled,
  title,
}) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState<number>(-1);
  const [localScore, setLocalScore] = useState(0);

  const handleSelectItem = (event: React.ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(event.target.value);

    setSelectedItem(index);
    setLocalScore(items[index].score);
    // la cuenta es:
    // (Total global) - (resto lo que se haya sumado) + (lo nuevo por sumar)
    setTotalScore((prev: number) => (prev - localScore + items[index].score))
  };

  return (
    <Box className={classes.danceCategory} p={2} maxWidth={300}>
      <Typography variant="h5">{title}</Typography>
      <RadioGroup
        value={selectedItem}
        onChange={handleSelectItem}
      >
        {items.map((item, index) => (
          <FormControlLabel
            value={index}
            key={index}
            control={<Radio />} 
            label={<Typography>{item.name} - Level: {item.level} Score: {item.score}</Typography>}
          />
        ))}
      </RadioGroup>
    </Box>
  );
};
