import React from 'react';
import { Box } from "@mui/material";
import FreeCategory from "../FreeCategory";
import { DanceCategory } from "../DanceCategory";
import { Category, ItemDetail, SelectedItemType } from "../types";
import { items } from "../variables";


const FreeSkatingCategorySelected = ({
  selectedCategory,
  selectedItems,
  freeSkatingCategorySelected,
  handleItemCountChange,
  setTotalScore,
}: {
  selectedCategory: Category,
  selectedItems: SelectedItemType,
  freeSkatingCategorySelected: boolean,
  handleItemCountChange: (
    name: string,
    score: number,
    ruleToCheck: string,
    increment: boolean
  ) => void,
  setTotalScore: (score: any) => void,
}) => {
  const jumps = items.filter((item) => item.type === "jump");
  const spins = items.filter((item) => item.type === "spin");
  const figures = items.filter((item) => item.type === "figure");
  const comboFigures = items.filter((item) => item.type === "comboFigures");
  const comboJumps = items.filter((item) => item.type === "comboJumps");
  const comboSpins = items.filter((item) => item.type === "comboSpins");
  const danceElements = items.filter((item) => item.type === "danceElement").map((item) => { return { name: item.name, level: '1', score: item.score } as ItemDetail });
  const danceElementSkatingSkill = danceElements.filter((item) => item.name.includes("Skating Skill"));
  const danceElementChoreo = danceElements.filter((item) => item.name.includes("Choreo Step"));

  const getItemScore = (name: string) => {
    const allItems = [...items];
    const selectedItem = allItems.find((item) => item.name === name);
    return selectedItem ? selectedItem.score : 0;
  };
  return (
    <Box display="flex" flexWrap="wrap">
      <FreeCategory
        items={jumps}
        handleItemIncrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualJumps", true)
        }
        handleItemDecrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualJumps", false)
        }
        selectedItems={selectedItems}
        getItemScore={getItemScore}
        title="Saltos (Sueltos)"
        categoryRules={selectedCategory?.rules}
        ruleToCheck="maxIndividualJumps"
      />
      <FreeCategory
        items={spins}
        handleItemIncrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualSpins", true)
        }
        handleItemDecrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualSpins", false)
        }
        selectedItems={selectedItems}
        getItemScore={getItemScore}
        title="Trompos (Sueltos)"
        categoryRules={selectedCategory?.rules}
        ruleToCheck="maxIndividualSpins"
      />
      <FreeCategory
        items={figures}
        handleItemIncrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualFigures", true)
        }
        handleItemDecrement={(name: string, score: number) =>
          handleItemCountChange(name, score, "maxIndividualFigures", false)
        }
        selectedItems={selectedItems}
        getItemScore={getItemScore}
        title="Figuras (Sueltas)"
        categoryRules={selectedCategory?.rules}
        ruleToCheck="maxIndividualFigures"
      />
      {freeSkatingCategorySelected && (selectedCategory.rules.maxComboFigures > 0) && (
        <FreeCategory
          items={comboFigures}
          handleItemIncrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboFigures", true)
          }
          handleItemDecrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboFigures", false)
          }
          selectedItems={selectedItems}
          getItemScore={getItemScore}
          title="Combo Figuras"
          categoryRules={selectedCategory?.rules}
          ruleToCheck="maxComboFigures"
        />)}
      {freeSkatingCategorySelected && (selectedCategory.rules.maxComboJumps > 0) && (
        <FreeCategory
          items={comboJumps}
          handleItemIncrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboJumps", true)
          }
          handleItemDecrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboJumps", false)
          }
          selectedItems={selectedItems}
          getItemScore={getItemScore}
          title="Combo Saltos"
          categoryRules={selectedCategory?.rules}
          ruleToCheck="maxComboJumps"
        />)}

       {freeSkatingCategorySelected && (selectedCategory.rules.maxComboSpins > 0) && (
        <FreeCategory
          items={comboSpins}
          handleItemIncrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboSpins", true)
          }
          handleItemDecrement={(name: string, score: number) =>
            handleItemCountChange(name, score, "maxComboSpins", false)
          }
          selectedItems={selectedItems}
          getItemScore={getItemScore}
          title="Combo Trompos"
          categoryRules={selectedCategory?.rules}
          ruleToCheck="maxComboSpins"
        />
      )}
      <DanceCategory
        items={danceElementChoreo}
        maxLevel={1}
        setTotalScore={setTotalScore}
        disabled={false}
        title='Choreo Step'
      />
      <DanceCategory
        items={danceElementSkatingSkill}
        maxLevel={1}
        setTotalScore={setTotalScore}
        disabled={false}
        title='Skating Skill'
      /> 
    </Box>
  )
}

export default FreeSkatingCategorySelected;