import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Alert from '@mui/material/Alert';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Typography,
  SelectChangeEvent,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button
} from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { categories, availableJudges, ages } from "./variables";
import { Category, Judge, Skater, SelectedItemType, EvaluationData } from "./types";
import SkaterService from "./skaterService";
import EvaluatedSkater from "./components/EvaluatedSkater";
import DanceCategories from "./components/DanceCategories";
import FreeSkatingCategorySelected from "./components/FreeSkatingCategorySelected";

const useStyles = makeStyles({
  mainContainer: {
    background: "linear-gradient(135deg, #4223d6, #e7aaff)",
    minHeight: "100vh",
    padding: "20px",
    boxSizing: "border-box",
    fontSize: "20px",
    width: "100%",
  },
  counterContainer: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    marginTop: '130px',
  },
  dropdownContainer: {
    marginBottom: "10px",
  },
});

const FrontEndPatinaje = () => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState<Category>();
  const [selectedAge, setSelectedAge] = useState<number | ''>('');
  const [selectedSkater, setSelectedSkater] = useState<Skater>();
  const [selectedItems, setSelectedItems] = useState<SelectedItemType>({});
  const [totalScore, setTotalScore] = useState(0.0);
  const [showColumns, setShowColumns] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingSkatersList, setLoadingSkatersList] = useState(false);
  const [disableSendScore, setDisableSendScore] = useState(false);

  const [open, setOpen] = useState(false);

  const openConfirmationModal = () => {
    setOpen(true);
  };

  const closeConfirmationModal = () => {
    setOpen(false);
  };

  const freeSkatingWithoutAge = selectedCategory && selectedCategory.type === "libre-solo";
  const freeSkatingCategorySelected =
    selectedCategory && selectedCategory.type === "libre";
  const danceCategorySelected =
    selectedCategory && (selectedCategory.type === "dance" || selectedCategory.type === 'cuarteto' || selectedCategory.type === 'show');

  const [skaters, setSkaters] = useState<Skater[] | undefined>(undefined);
  const [selectedJudge, setSelectedJudge] = useState<Judge | undefined>(undefined);
  const [error, setError] = useState<string | undefined>(undefined);
  const [warning, setWarning] = useState<string | undefined>(undefined);
  const [success, setSuccess] = useState<string | undefined>(undefined);
  const [canEvaluate, setCanEvaluate] = useState(false);

  const cleanAlerts = () => {
    setWarning(undefined);
    setError(undefined);
    setSuccess(undefined);
  }

  const clearStates = () => {
    setSkaters(undefined);
    setSelectedSkater(undefined);
    setCanEvaluate(false);
    setSelectedItems({});
    setTotalScore(0.0);
  }
  const fetchSkatersByCategory = async (categoryName: string, age?: number, judgeName?: string) => {
    clearStates();
    setLoadingSkatersList(true)
    try {
      const params = {
        categories: [categoryName],
        ...(age && { age }),
        ...(judgeName && { judgeName }),
      };

      const skatersData = await SkaterService.getSkatersByCategory(params);
      cleanAlerts();
      setLoadingSkatersList(false)
      setSkaters(skatersData);
    } catch (error: any) {
      setLoadingSkatersList(false)
      const { message, type } = error.response.data;
      if (type === 'warning') return setWarning(message);
      setError(message)
    }
  };

  const handleCategoryChange = async (event: SelectChangeEvent<string>) => {
    setCanEvaluate(false);
    cleanAlerts();
    setSelectedSkater(undefined);
    const selectedName = event.target.value;
    const selectedCategory = categories.find(
      (category) => category.name === selectedName
    );

    if (selectedCategory) {
      setSelectedCategory(selectedCategory);
      // setSelectedSkater(undefined)
      setSelectedAge('');
      // setSelectedItems({});
      setShowColumns(true);

      const danceShowCategories =  (
        selectedCategory.type === "dance" 
        || selectedCategory.type === 'cuarteto'
        || selectedCategory.type === 'show'
        || selectedCategory.type === 'couple'
        || selectedCategory.type === 'libre-solo'
      );

      if (danceShowCategories) {
        await fetchSkatersByCategory(selectedCategory.name);
      }
    }
  };

  const handleAgeChange = async (event: SelectChangeEvent<number>) => {
    const selectedAge = event.target.value as number;

    setSelectedAge(selectedAge);
    if (selectedCategory && selectedAge && selectedJudge) {
      try {
        await fetchSkatersByCategory(selectedCategory.name, selectedAge, selectedJudge?.name);
      } catch (error) {
        console.error('Error fetching skaters:', error);
      }
    }
  };

  const handleJudgeChange = (event: SelectChangeEvent<string>) => {
    const selectedJudgeName = event.target.value;
    const judge = availableJudges.find((judge) => judge.name === selectedJudgeName);
    setSelectedJudge(judge);
    if (selectedSkater && judge) isValidJudgeForSkater(selectedSkater, judge);
  };

  const stopLoading = () => {
    setTimeout(() => {
      console.log('stop loading')
      setLoading(false)
    }, 500);
  }

  const isValidJudgeForSkater = (skater: Skater, judge: Judge): boolean => {
    // si es su primera evaluacion del dia, entonces lo puede evaluar cualquier Juez
    if (!skater.todaysEvaluations || skater.todaysEvaluations.length === 0) {
      setCanEvaluate(true);
      cleanAlerts();
      return true;
    }

    // Guardo los nombres de los jueces que ya evaluaron al patinador elegido.
    const skaterJudgesNames = skater.todaysEvaluations.map(ev => ev.judge.name);
    // Guardo los nombres de TODOS los jueces disponibles
    const availableJudgesNames = availableJudges.map(j => j.name);
    // chequeo si ya fue evaluado por TODOS los jueces y aviso en caso que así sea.
    const judgedByAll = availableJudgesNames.every((judgeName) => skaterJudgesNames.includes(judgeName));

    if (judgedByAll) {
      const fullname = `${skater.name} ${skater.lastname}`;
      setCanEvaluate(false);
      setSuccess(`El patinador ${fullname} en la categoría ${selectedCategory?.name} ya fue evaluado por todos los jueces`);
      return false;
    }

    // Si no fue evaluado por TODOS los jueces, Ver si el Juez elegido ya lo evaluó o no.
    const alreadyJudged = skaterJudgesNames.includes(judge.name);

    if (alreadyJudged) {
      const fullname = `${skater.name} ${skater.lastname}`;
      setCanEvaluate(false);
      setError(`El ${judge.name} ya evaluó a ${fullname} en la categoría ${selectedCategory?.name}`);
      return false;
    }
    setCanEvaluate(true);
    cleanAlerts();
    return true;
  }

  const handleSkaterChange = (event: SelectChangeEvent<string>) => {
    cleanAlerts();
    setTotalScore(0.0);
    setSelectedItems({});
    const selectedSkaterId = event.target.value;
    const skater = skaters?.find((skater) => skater._id === selectedSkaterId);
    if (skater && selectedJudge) {
      setSelectedSkater(skater);
      const isValid = isValidJudgeForSkater(skater, selectedJudge)
      if (isValid) setLoading(true);
      stopLoading();
    }
  };

  const handleItemCountChange = (
    name: string,
    score: number,
    ruleToCheck: string,
    increment: boolean
  ) => {
    const maxRule = selectedCategory?.rules[ruleToCheck];
    const currentCount = selectedItems[name]?.count || 0;
    const shouldIncrement = increment ? 1 : -1;

    if (
      (increment && currentCount < maxRule) ||
      (!increment && currentCount > 0)
    ) {
      const updatedItems = { ...selectedItems };
      const newCount = currentCount + shouldIncrement;
      const currentScore = parseFloat((newCount * score).toFixed(2));
      updatedItems[name] = { count: newCount, score: currentScore };
      setSelectedItems(updatedItems);
      const total = parseFloat((totalScore + (shouldIncrement * score)).toFixed(2));
      if (total >= 0) {
        setTotalScore(total);
      } else {
        // si el totalScore por alguna razón quedó negativo, ponerlo en 0
        setTotalScore(0);
      }
    }
  };

  

  const handleAdditionalScore = () => {
    const newTotalScore = parseFloat((totalScore + 0.5).toFixed(2));
    setTotalScore(newTotalScore);
  };

  const handleLessScore = () => {
    const newTotalScore = parseFloat((totalScore - 0.5).toFixed(2));
    if (newTotalScore >= 0) {
      setTotalScore(newTotalScore);
    } else {
      setTotalScore(0);
    }
  };

  const confirmScore = () => {
    if (totalScore === 0) {
      openConfirmationModal();
      return;
    }
    sendScore();
  }

  const sendScore = async () => {
    if (!selectedJudge || !selectedSkater || !selectedCategory) {
      alert('Se debe seleccionar Juez, Categoría y Patinador');
      return;
    };

    setDisableSendScore(true);
    const judgeData = {
      name: selectedJudge.name,
      score: totalScore,
    };

    const today = new Date()
    const evaluation: EvaluationData = {
      _skaterId: selectedSkater._id,
      createdAt: new Date(
        new Date(today.toLocaleDateString('en-US', { timeZone:'America/Argentina/Buenos_Aires' }))
      ),
      category: selectedCategory.name,
      judge: judgeData
    };

    // testeando 2 calls "simultaneas"
    // const eval1: EvaluationData = {
    //   _skaterId: '66244960ed62899d7f872d06',
    //   createdAt: new Date(),
    //   category: 'ELITE SHORT PROGRAM',
    //   judge: {
    //     name: 'Juez 1',
    //     score: 1
    //   }
    // };

    // const eval2: EvaluationData = {
    //   _skaterId: '66244960ed62899d7f872d06',
    //   createdAt: new Date(),
    //   category: 'ELITE SHORT PROGRAM',
    //   judge: {
    //     name: 'Juez 2',
    //     score: 2
    //   }
    // };

    console.log('evaluation', evaluation);

    try {
      await SkaterService.updateTodaysEvaluations(evaluation);
      await fetchSkatersByCategory(selectedCategory.name, (selectedAge || 0), selectedJudge?.name);
      setSelectedSkater(undefined);
      setTotalScore(0);
      setSelectedItems({});
      setDisableSendScore(false);
      // testeando 2 calls "simultaneas"
      // console.log(eval1)
      // SkaterService.updateTodaysEvaluations(eval1);
      // console.log(eval2)
      // SkaterService.updateTodaysEvaluations(eval2);
    } catch (error) {
      setDisableSendScore(false);
      setError("Error al enviar la calificación. Por favor, inténtalo de nuevo.");
    }
  };

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Typography variant="h5">
          Roll Art de Patinaje Artístico
        </Typography>
        {success && <Alert severity="success">{success}</Alert>}
        {error && <Alert severity="warning">{error}</Alert>}
        {warning && <Alert severity="error">{warning}</Alert>}
        <FormGroup className={classes.dropdownContainer}>
          <FormControl fullWidth sx={{ margin: 2 }}>
            <InputLabel id="judge-label">Selecciona un juez:</InputLabel>
            <Select
              labelId="judge-label"
              id="judge"
              value={selectedJudge?.name || ""}
              onChange={handleJudgeChange}
              label="Selecciona un juez"
            >
              <MenuItem value="">Seleccionar juez</MenuItem>
              {availableJudges.map((judge, index) => (
                <MenuItem key={index} value={judge.name}>
                  {judge.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ margin: 2 }}>
            <InputLabel
              id="category-label"
              className={classes.dropdownContainer}
              sx={{ marginBottom: '8px' }}
            >
              Selecciona una categoría:
            </InputLabel>
            <Select
              labelId="category-label"
              id="category"
              value={selectedCategory ? selectedCategory?.name : ""}
              onChange={handleCategoryChange}
              label="Selecciona una categoría"
            >
              <MenuItem value="">Seleccionar categoría</MenuItem>
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.name}>
                  {category.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
           {freeSkatingCategorySelected &&
            <FormControl fullWidth sx={{ margin: 2 }}>
              <InputLabel id="age-label" sx={{ marginBottom: '8px', marginTop: '8px' }}>Selecciona una edad:</InputLabel>
              <Select
                labelId="age-label"
                id="age"
                value={selectedAge}
                onChange={handleAgeChange}
                label="Selecciona una edad"
              >
                <MenuItem value="">Seleccionar edad</MenuItem>
                {ages.map((index) => (
                  <MenuItem key={index} value={index + 4}>{index + 4} años</MenuItem>
                ))}
              </Select>
            </FormControl>
          }

          <FormControl fullWidth sx={{ margin: 2 }}>
            {loadingSkatersList && (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <span>Cargando patinadores...</span>
                <CircularProgress sx={{
                  marginLeft: '15px'
                }} />
              </Box>
            )}
            {!loadingSkatersList && (
              <>
                <InputLabel id="skater-label" sx={{ marginBottom: '8px' }}>Selecciona un patinador:</InputLabel>
                <Select
                  disabled={loadingSkatersList}
                  labelId="skater-label"
                  id="skater"
                  value={selectedSkater ? selectedSkater._id : ''}
                  onChange={handleSkaterChange}
                  label="Selecciona un patinador" // Agregar el prop label
                >
                  <MenuItem value="">Seleccionar patinador</MenuItem>
                  {skaters && skaters
                    .filter(
                      (skater) =>
                        selectedCategory &&
                        skater.categories.includes(selectedCategory.name)
                    )
                    .map((skater) => (
                      <MenuItem
                        key={skater._id}
                        value={skater._id}
                      >
                        {skater.name} {skater.lastname}
                      </MenuItem>
                    ))}
                </Select>
              </>
            )}
          </FormControl>
        </FormGroup>
      </Box>
      {loading && <h3>Cargando info del patinador...</h3>}
      {!loading && (
        <div>
          {selectedSkater && selectedJudge && canEvaluate &&
            <EvaluatedSkater
              selectedSkater={selectedSkater}
              selectedJudge={selectedJudge}
              totalScore={totalScore}
              error={error}
              handleAdditionalScore={handleAdditionalScore}
              handleLessScore={handleLessScore}
              sendScore={confirmScore}
              disableSendScore={disableSendScore}
              setError={setError}
            />
          }

          {selectedSkater && showColumns && freeSkatingCategorySelected && canEvaluate && (
            <FreeSkatingCategorySelected
              selectedCategory={selectedCategory}
              selectedItems={selectedItems}
              freeSkatingCategorySelected={freeSkatingCategorySelected}
              setTotalScore={setTotalScore}
              handleItemCountChange={handleItemCountChange}
            />
          )}

          {selectedSkater && showColumns && freeSkatingWithoutAge && canEvaluate && (
            <FreeSkatingCategorySelected
              selectedCategory={selectedCategory}
              selectedItems={selectedItems}
              freeSkatingCategorySelected={freeSkatingWithoutAge}
              setTotalScore={setTotalScore}
              handleItemCountChange={handleItemCountChange}
            />
          )} 

          {selectedSkater && canEvaluate && showColumns && danceCategorySelected && (
            <DanceCategories
              selectedCategory={selectedCategory}
              setTotalScore={setTotalScore}
            />
          )} 
        </div>
      )}
      <Dialog
        open={open}
        onClose={closeConfirmationModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"El puntaje final es 0 (cero)"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Desea guardarlo igualmente?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeConfirmationModal}>No</Button>
          <Button onClick={() => {
            sendScore();
            closeConfirmationModal();
          }} autoFocus>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
export default FrontEndPatinaje;
