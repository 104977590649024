import React, { useState } from 'react';
import { Skater } from './types';
import SkaterService from './skaterService';
import { TextField, Button, Checkbox, FormGroup, FormControl, InputLabel, Select, MenuItem, ListItemText, SelectChangeEvent, Box } from '@mui/material';
import { categories } from './variables';
import { Snackbar, Typography } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';

const SkaterForm = () => {
  const [skaterData, setSkaterData] = useState<Skater>({ _id: '', name: '', lastname: '', age: undefined, club: '', categories: [], dni: '', totalScore: 0, evaluations: [] });
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isSavingSkater, setIsSavingSkater] = useState(false);

  const isSaveDisabled = () => {
    const { name, lastname, age, categories } = skaterData;
    return (!name || !lastname || !age || categories.length === 0) ? true : false;
  }

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    const newValue = name === 'age' ? parseInt(value, 10) : value;
    setSkaterData({ ...skaterData, [name]: newValue });
  };

  const handleCategoryChange = (event: SelectChangeEvent<string | string[]>) => {
    const selectedCategories = Array.isArray(event.target.value) ? event.target.value : [event.target.value];
    setSkaterData({ ...skaterData, categories: selectedCategories, evaluations: [] });
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSavingSkater(true);
    try {
      const response = await SkaterService.create(skaterData);
      if (response.status === 200) {
        setShowSuccessBanner(true); // Mostrar el banner de éxito
        setIsSavingSkater(false);
        // Limpia el formulario restableciendo el estado
        setSkaterData({ _id: '', name: '', lastname: '', age: undefined, club: '', categories: [], dni: '', evaluations: [] });

        setTimeout(() => setShowSuccessBanner(false), 3000); // Ocultar el banner después de 3 segundos
      } else {
        setIsSavingSkater(false);
        console.error('Error creating skater:', response.statusText);
        setError('Error al cargar los datos. Por favor, inténtalo de nuevo.'); 
      }
    } catch (error) {
      setIsSavingSkater(false);
      console.error('Error creating skater:', error);
      setError('Error al cargar los datos. Por favor, inténtalo de nuevo.'); // Establecer el mensaje de error
    }
  };
  const formStyle = {
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
    backgroundColor: '#ffffff',
    color: '#000000',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Alinea los elementos en el centro verticalmente
    top: '120px'
  };

  const buttonStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'white',
    background: 'linear-gradient(135deg, #4223d6, #e7aaff)',
    '&:hover': {
      background: 'linear-gradient(90deg, #4223d6, #e7aaff)',
    },
    padding: '10px 20px',
    marginTop: '20px',
    display: 'flex'
  };

  return (
    <>
      <FormControl component="form" onSubmit={handleSubmit} sx={formStyle}>
        <Typography variant={'h4'}>Ingresar Patinador@</Typography>
        <FormGroup>
          <TextField error={!!error} id="name" name="name" label="Nombre" value={skaterData.name} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField error={!!error} id="lastname" name="lastname" label="Apellido" value={skaterData.lastname} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField error={!!error} id="age" name="age" label="Edad" type="number" value={skaterData.age || ''} onChange={handleInputChange} fullWidth margin="normal" />
          <TextField error={!!error} id="club" name="club" label="Club" value={skaterData.club} onChange={handleInputChange} fullWidth margin="normal" />
          <Box sx={{ width: '100%' }}>
            <InputLabel 
              id="category-label"
              sx={{ top: 'unset', left: 'unset' }}>Categorías</InputLabel>
            <Select
            error={!!error}
              labelId="category-label"
              label="Categorías"
              id="category-label"
              name='categories'
              multiple
              value={skaterData.categories}
              onChange={handleCategoryChange}
              renderValue={(selected) => (selected as string[]).join(', ')}
              fullWidth
            >
              {categories.map((category, index) => (
                <MenuItem key={index} value={category.name}>
                  <Checkbox checked={skaterData.categories.includes(category.name)} />
                  <ListItemText primary={category.name} />
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Button
            type="submit"
            variant="contained"
            sx={buttonStyle}
            disabled={isSaveDisabled() || isSavingSkater}
          >
            Cargar patinador
            {isSavingSkater && <CircularProgress sx={{
              marginLeft: '15px',
            }}  size="1rem" />}
          </Button>
          { 
            showSuccessBanner && <Snackbar
              open={showSuccessBanner}
              autoHideDuration={6000}
              onClose={() => setShowSuccessBanner(false)}
              message="Patinador@ cargado exitosamente."
              style={{ backgroundColor: 'green', color: 'white', zIndex: 1000, borderRadius: '10px' }}
            />
          }
          {
            error && (
              <Snackbar
                open={!!error}
                autoHideDuration={6000}
                onClose={() => setError(null)}
                message={error}
                color='red'
                style={{ backgroundColor: 'red', color: 'white', zIndex: 1000, borderRadius: '10px' }}
              />
            )
          }
        </FormGroup>
      </FormControl>
    </>
  );
};

export default SkaterForm;
