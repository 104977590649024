import React from 'react';
import { Box, Button, Menu, MenuItem } from '@mui/material';
import { Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';
import ListIcon from '@mui/icons-material/List';

interface MainMenuProps {
  onSelectOption: (option: string) => void;
  children?: React.ReactNode; // Acepta elementos secundarios
}

function MainMenu({ onSelectOption, children }: MainMenuProps) {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleNavigate = (path: string, option: string) => {
    onSelectOption(option);
    handleClose();
  };

  const buttonStyle = {
    fontWeight: 'bold',
    fontSize: '16px',
    color: 'white',
    backgroundColor: 'linear-gradient(135deg, #4223d6, #e7aaff)',
    '&:hover': {
      backgroundColor: 'linear-gradient(90deg, #4223d6, #e7aaff)',
    },
    padding: '10px',
  }
  
  return (
    <Box sx={{ flexGrow: 1}}>
      <Button
        id="mini-menu"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={buttonStyle}
        variant='contained'
      >
       <Box sx={{ display: 'flex', alignItems: 'center' }}>
       <ListIcon sx={{ ml: 1 }}/>  
       <Typography style={{ marginLeft: '5px', fontWeight: 'bold'}}>Menu</Typography>  
       </Box>
      </Button>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem component={Link} to="/cargar-patinador" onClick={() => handleNavigate("/cargar-patinador", 'cargar-patinador')}>
          Cargar Patinadores
        </MenuItem>
        <MenuItem component={Link} to="/evaluar-patinador" onClick={() => handleNavigate("/evaluar-patinador", 'evaluar')}>
          Evaluar Patinadores
        </MenuItem>
        <MenuItem component={Link} to="/generar-reporte" onClick={() => handleNavigate("/generar-reporte", 'generar-reporte')}>
          Generar Reportes
        </MenuItem>
        {children} 
      </Menu>
    </Box>
  );
}

export default MainMenu;
