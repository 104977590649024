import React from 'react';
import { Box, Typography, Button, ButtonProps } from '@mui/material';
import { makeStyles } from "@material-ui/core/styles";

interface ItemDetail {
  name: string;
  score: number;
  level?: string;
  type?: string;
}

interface Props {
  items: ItemDetail[];
  handleItemIncrement: (name: string, score: number) => void;
  handleItemDecrement: (name: string, score: number) => void;
  selectedItems: { [key: string]: { count: number; score: number } };
  getItemScore: (name: string) => number;
  title: string;
  categoryRules?: any; 
  ruleToCheck?: string;
}

const RoundedButton: React.FC<ButtonProps> = ({ children, disabled, ...props }) => (
  <Button {...props} sx={{ width: 'auto', height: 'auto', borderRadius: '8px', padding: '8px', minWidth: 'auto', margin: '5px' }} disabled={disabled}>
    {children}
  </Button>
);

const useStyles = makeStyles({
  menuPaper: {
    top: "auto !important",
    bottom: 0,
  },
  scoreTotal: {
    fontSize: "30px",
    marginTop: "20px",
    fontWeight: 500,
  },
  customItems: {
    fontSize: "20px",
    fontWeight: 500,
  },
  title: {
    fontSize: "24px",
    fontWeight: 500,
    marginBottom: "20px",
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "stretch",
    backgroundColor: "#fff",
    padding: "20px",
    borderRadius: "5px",
    margin: "10px",
    overflowY: "auto",
    maxWidth: "100%",
    minWidth: '350px'
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "1px solid #ccc",
    padding: "10px 0",
  },
  scoreContainer: {
    marginLeft: "20px",
  },
  button: {
    width: "40px", // Ancho aumentado para los botones
  },
})

const FreeCategory: React.FC<Props> = ({ items, title, handleItemIncrement, handleItemDecrement, selectedItems, getItemScore, categoryRules, ruleToCheck }) => {
  const maxRule = categoryRules ? categoryRules[ruleToCheck || ''] : undefined;
  const classes = useStyles();
  // Contador de elementos seleccionados en esta columna
  const columnSelectedItems = items.reduce((acc, item) => {
    const selectedItem = selectedItems[item.name];
    return selectedItem ? acc + selectedItem.count : acc;
  }, 0);

  const canIncrementItem = (name: string) => {
    const itemCount = selectedItems[name]?.count || 0;
    return maxRule === undefined || itemCount < maxRule;
  };

  const canDecrementItem = (name: string) => selectedItems[name]?.count > 0;
  
  return (
    <Box className={classes.columnContainer}>
      <Typography variant="h5">{title}</Typography>
      {items.map((item, index) => (
        <Box key={index} className={classes.itemContainer}>
          <Box style={{ display: 'flex', alignItems: 'left' }}>
            <RoundedButton
              className={classes.button}
              variant="contained"
              onClick={() => {
                if (canIncrementItem(item.name) && columnSelectedItems < maxRule) {
                  handleItemIncrement(item.name, item.score);
                }
              }}
              disabled={!canIncrementItem(item.name) || columnSelectedItems >= maxRule}
            >
              +
            </RoundedButton>
            <Typography sx={{ alignSelf: 'center' }}>{selectedItems[item.name]?.count || 0}</Typography>
            <RoundedButton
              className={classes.button}
              variant="contained"
              onClick={() => {
                if (canDecrementItem(item.name)) {
                  handleItemDecrement(item.name, item.score);
                }
              }}
              disabled={!canDecrementItem(item.name)}
            >
              -
            </RoundedButton>
          </Box>
          <Typography>{item.name}</Typography>
          <Typography className={classes.scoreContainer}>
            total: {((getItemScore(item.name) * (selectedItems[item.name]?.count || 0)).toFixed(2))}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

export default FreeCategory;



