import React, { useState } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormGroup,
  Typography,
  SelectChangeEvent,
  Button,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { categories, ages } from "../variables";
import SkaterService from "../skaterService";

const useStyles = makeStyles({
  mainContainer: {
    background: "linear-gradient(135deg, #4223d6, #e7aaff)",
    minHeight: "100vh",
    padding: "20px",
    boxSizing: "border-box",
    fontSize: "20px",
    width: "100%",
  },
  container: {
    backgroundColor: "#fff",
    borderRadius: "10px",
    padding: "20px",
    marginTop: '130px',
  },
});

type SkaterRowType = {
  name: string;
  lastname: string;
  category: string;
  age: number;
  judge1: number;
  judge2: number;
  judge3: number;
  total: number;
}

const Reporte = () => {
  const classes = useStyles();
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAge, setSelectedAge] = useState<number>(-1);
  const [skatersData, setSkatersData] = useState<undefined | SkaterRowType[]>(undefined);

  const handleCategoryChange = (event: SelectChangeEvent<string>) => {
    const category = event.target.value;
    setSelectedCategory(category);
  }

  const handleAgeChange = (event: SelectChangeEvent<number>) => {
    const age = event.target.value as number;
    setSelectedAge(age);
  }

  const handleFetchReport = async () => {
    try {
      const skaters = await SkaterService.getReport(selectedCategory, selectedAge);
      setSkatersData(skaters.data);
    } catch (e) {
      console.error('Falló el reporte. Mostrar error al ususario');
    }
  }

  return (
    <Box className={classes.mainContainer}>
      <Box className={classes.container}>
        <Typography variant="h5">
          Generar Reporte
        </Typography>
        <Box sx={{ marginTop: '15px' }}>
          <FormGroup>
            <FormControl>
              <InputLabel id="catetory">Selecciona categoría:</InputLabel>
              <Select
                labelId="category"
                id="category"
                value={selectedCategory}
                onChange={handleCategoryChange}
                label="Selecciona una categoría"
              >
                <MenuItem value="">Seleccionar categoría</MenuItem>
                {categories.map((category, index) => (
                  <MenuItem key={index} value={category.name}>
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <FormGroup>
            <FormControl>
              <InputLabel id="age">Selecciona edad:</InputLabel>
              <Select
                labelId="age"
                id="age"
                value={selectedAge}
                onChange={handleAgeChange}
                label="Selecciona edad"
              >
                <MenuItem value="-1">Seleccionar edad</MenuItem>
                {ages.map((age, index) => (
                  <MenuItem key={index} value={index + 4}>
                    {age + 4} años
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </FormGroup>
        </Box>
        <Box sx={{ marginTop: '15px' }}>
          <Button
            variant="contained"
            disabled={!selectedCategory && selectedAge <= 0}
            onClick={handleFetchReport}
          >
            Listar
          </Button>
          <Button
            variant="outlined"
            onClick={() => setSkatersData(undefined)}
            sx={{ marginLeft: '15px' }}
            disabled={!skatersData}
          >
            Reset
          </Button>
        </Box>
      </Box>
      <Box sx={{ marginTop: '15px' }}>
        {skatersData && skatersData.length > 0 && (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{fontWeight: 'bold' }}>Nombre</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Edad</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Categoría</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Juez 1</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Juez 2</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Juez 3</TableCell>
                    <TableCell sx={{fontWeight: 'bold' }} align="right">Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {skatersData.map((row) => (
                    <TableRow
                      key={row.name}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {row.name} {row.lastname}
                      </TableCell>
                      <TableCell align="right">{row.age}</TableCell>
                      <TableCell align="right">{row.category}</TableCell>
                      <TableCell align="right">{row?.judge1}</TableCell>
                      <TableCell align="right">{row?.judge2}</TableCell>
                      <TableCell align="right">{row?.judge3}</TableCell>
                      <TableCell align="right">{row?.total}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        
      </Box>
    </Box>
  )
}

export default Reporte;