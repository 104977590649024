import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import logo from './assets/logo.png'; 
import RollerSkatingIcon from '@mui/icons-material/RollerSkating';

const useStyles = makeStyles({
  banner: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    flexDirection: 'row',
    backgroundSize: 'cover',
    justifyContent: 'left',
    width: '100%',
    backgroundColor: '#ffffff',
    position: 'fixed',
    zIndex: 2,
    boxShadow: '0 4px 2px -2px gray'
  },
  logo: {
    maxWidth: '100px',
    marginRight: '10px', 
  },
  titleContainer: {
    display: 'flex', 
    alignItems: 'center',
    marginRight: '50px',
    justifyContent: 'space-between',
    width: '100%', 
  },
  title: {
    fontSize: '40px',
    fontWeight: 'bold',
    fontFamily: 'Trebuchet MS, sans-serif',
    display: 'flex',
  },
});

interface Props {
  children: React.ReactNode;
}

const Banner: React.FC<Props> = ({children}) => {
  const classes = useStyles();

  return (
    <Box className={classes.banner}>
      <img src={logo} alt="Logo" className={classes.logo} />
      <Box className={classes.titleContainer}>
        <Typography variant="h2" className={classes.title}>
          Argenshow Roll-Art <RollerSkatingIcon sx={{fontSize: 50}}/>
        </Typography>
        <Box>{children}</Box> 
      </Box>
    </Box>
  );
};

export default Banner;